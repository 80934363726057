import { defineComponent as g, openBlock as i, createElementBlock as o, normalizeClass as t, createElementVNode as n, normalizeStyle as a, createCommentVNode as l, createVNode as c, withCtx as m, renderSlot as d } from "vue";
import h from "./Container.js";
import { _ as u } from "./chunks/_plugin-vue_export-helper.CHgC5LLL.js";
import './css/Hero.css';const v = { class: "hero-cover" }, y = /* @__PURE__ */ g({
  __name: "Hero",
  props: {
    /**
     * Defines the height of the hero.
     *
     * @default 'full'
     */
    heightTmp: {
      type: String,
      default: "prominent"
    },
    /**
     * The URL of the image used as a background.
     */
    imgTmp: {
      type: String
    },
    /**
     * Defines the horizontal placement of the background image.
     */
    imgTmpAlignX: {
      type: String,
      default: "center"
    },
    /**
     * Defines the vertical placement of the background image.
     */
    imgTmpAlignY: {
      type: String,
      default: "stretch"
    },
    /**
     * The CSS background of the overlay on top of the cover image.
     */
    overlay: {
      type: String
    },
    /**
     * deactivates the bottom-line.
     */
    bottomline: {
      type: Boolean,
      default: !1
    },
    /**
     * Defines the width of the content.
     */
    contentWidth: {
      type: String,
      default: "short"
    },
    /**
     * Defines the vertical alignment of the content.
     */
    contentAlignY: {
      type: String,
      default: "bottom"
    },
    /**
     * makes hero cards-compatible if set to 'card'.
     */
    target: {
      type: String,
      default: "page"
    },
    /**
     * Defines the padding of the content.
     *
     * - `text` - Applies standard padding like in sections.
     * - `banner` - Sticks the content to the edges on phone screens.
     */
    contentType: {
      type: String,
      default: "text"
    }
  },
  setup(e) {
    return (r, f) => (i(), o("div", {
      class: t(["hero", [
        e.target === "page" ? `hero-${e.heightTmp}` : "hero-mini card-hero",
        `hero-align-content-${e.contentAlignY}`,
        e.bottomline ? "hero-bottomline" : ""
      ]])
    }, [
      n("div", v, [
        n("div", {
          class: t(e.target === "page" ? "hero-cover-image" : "static-cover-image"),
          style: a({
            backgroundImage: `url(${e.imgTmp})`,
            backgroundPositionX: e.target === "page" ? e.imgTmpAlignX === "stretch" ? "left" : e.imgTmpAlignX === "cover" ? "center" : e.imgTmpAlignX : "center",
            backgroundPositionY: e.target === "page" ? e.imgTmpAlignY === "stretch" ? "top" : e.imgTmpAlignY === "cover" ? "center" : e.imgTmpAlignY : "center",
            backgroundSize: e.target === "page" ? e.imgTmpAlignX === "cover" || e.imgTmpAlignY === "cover" ? "cover" : `${e.imgTmpAlignX === "stretch" ? "100%" : "auto"} ${e.imgTmpAlignY === "stretch" ? "100%" : "auto"}` : "500px"
          })
        }, [
          e.overlay ? (i(), o("div", {
            key: 0,
            class: "hero-cover-overlay",
            style: a({ background: e.overlay })
          }, null, 4)) : l("", !0)
        ], 6)
      ]),
      n("div", {
        class: t(["hero-content", [`hero-content-${e.contentWidth}`, `hero-content-${e.contentType}`]])
      }, [
        c(h, null, {
          default: m(() => [
            d(r.$slots, "default", {}, void 0, !0)
          ]),
          _: 3
        })
      ], 2)
    ], 2));
  }
}), S = /* @__PURE__ */ u(y, [["__scopeId", "data-v-96d2ba12"]]);
export {
  S as default
};
